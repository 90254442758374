import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    headerRow: {
      main?: string;
      hover?: string;
    };
    successRow: {
      main: string;
      hover: string;
    };
    errorRow: {
      main: string;
      hover: string;
    };
    warningRow: {
      main: string;
      hover: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    headerRow?: {
      main?: string;
      hover?: string;
    };
    successRow?: {
      main?: string;
      hover?: string;
    };
    errorRow?: {
      main?: string;
      hover?: string;
    };
    warningRow?: {
      main?: string;
      hover?: string;
    };
  }
}

// Create a theme instance.
// wrap it in function that generates responsive font sizes
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#005b85",
        contrastText: "#fff!important",
      },
      secondary: {
        main: "#00856d",
        contrastText: "#fff!important",
      },
    },
    headerRow: {
      main: "rgb(153, 207, 236)!important",
      hover: "rgb(128, 195, 232)!important",
    },
    successRow: {
      main: "rgb(171, 203, 173)!important",
      hover: "rgb(150, 190, 152)!important",
    },
    errorRow: {
      main: "rgb(237, 171, 171)!important",
      hover: "rgb(233, 151, 151)!important",
    },
    warningRow: {
      main: "rgb(247, 196, 153)!important",
      hover: "rgb(246, 181, 128)!important",
    },
  })
);

export default theme;
