import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAx9ER4-CBiUR5owvRSwI85GZs9BUBRz1k",
  authDomain: "rex-pharmacy-df847.firebaseapp.com",
  projectId: "rex-pharmacy-df847",
  storageBucket: "rex-pharmacy-df847.appspot.com",
  messagingSenderId: "622289418159",
  appId: "1:622289418159:web:08c26a13ece3d3b746a15a",
  measurementId: "G-K3T5CK75CZ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

if (typeof window !== "undefined") {
  if (process.env.NODE_ENV === "development") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN;
  }
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

export { db, auth };
