import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function MaxSizeLoading() {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={80} />
    </Box>
  );
}
