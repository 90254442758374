import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import Auth from "components/Auth";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import Script from "next/script";
import { SnackbarProvider } from "notistack";
import createEmotionCache from "../lib/client/createEmotionCache";
import theme from "../theme";

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        margin: "0",
      },
      // needed for sticky bottom footer
      "html, body, #__next": { height: "100%", paddingRight: "0 !important" },
      "#__next": {
        display: "flex",
        flexDirection: "column",
      },
      // Removes the watermark for utilizing unlicensed MUI X products while in development
      '[style*="z-index: 100000"]': {
        display: "none",
      },
    }}
  />
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function MyApp(props) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta charSet="utf-8" />
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {/* PWA primary color */}
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
      <Script
        id="google-maps"
        async
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places,maps&callback=Function.prototype&loading=async`}
      />
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple
                 baseline to build upon. */}
          <CssBaseline />
          {globalStyles}
          <SessionProvider session={session}>
            <Auth>
              <Component {...pageProps} />
            </Auth>
          </SessionProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
